// in config.js
import { createChatBotMessage } from 'react-chatbot-kit';
import BotAvatar from './chatbotComponents/BotAvatar';
import Options from './chatbotComponents/Options';

const config = {
  initialMessages: [
    createChatBotMessage(
      'Hi there! Welcome to CliClaim. How can I assist you with your claim today? You can ask questions',
      {
        widget: 'options'
      }
    )
  ],
  botName: 'CliClaim Bot',
  customComponents: { botAvatar: (props) => <BotAvatar {...props} /> },
  widgets: [
    {
      widgetName: 'options',
      widgetFunc: (props) => <Options {...props} />
    }
  ]
};

export default config;
