// in chatbotComponents/Options.jsx
import React from 'react';
import './options.css';

const Options = (props) => {
  const options = [
    {
      text: 'What is CliClaim?',
      handler: props.actionProvider.handleWhatIsCliClaim,
      id: 1
    },
    {
      text: 'How to make a claim?',
      handler: props.actionProvider.handleHowToMakeAClaim,
      id: 2
    },
    {
      text: 'How to change my Informations?',
      handler: props.actionProvider.howToModifyMyInformations,
      id: 3
    }
  ];

  const buttonsMarkup = options.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default Options;
