import React from 'react';
import './botAvatar.css';

function BotAvatar() {
  return (
    <div className="botAvatarWrapper">
      <div className="botAvatar"> </div>
    </div>
  );
}

export default BotAvatar;
