import { Box } from '@mui/material';
import HeaderSearch from './Search';
import HeaderNotifications from './Notifications';
import { useEffect, useState } from 'react';
import api from 'src/redux/config';

function HeaderButtons({ notifications, fetchNotification }) {
  return (
    <Box sx={{ mr: 2, display: 'flex' }}>
      <HeaderSearch />
      <HeaderNotifications
        notifications={notifications}
        fetchNotification={fetchNotification}
      />
    </Box>
  );
}

export default HeaderButtons;
