import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router';
import './index.css';
import './assets/css/home.css';
import './assets/css/footer.css';
import './assets/css/navbar.css';
import './assets/css/signin.css';
import './assets/css/signup.css';
import './assets/css/motionSlider.css';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useSelector } from 'react-redux';
import Chatbot from 'react-chatbot-kit';
import config from './content/applications/chatbot/config';
import MessageParser from './content/applications/chatbot/MessageParser';
import ActionProvider from './content/applications/chatbot/ActionProvider';
import 'react-chatbot-kit/build/main.css';
import chatbotIcon from './assets/images/chatbot.png';
import { io } from 'socket.io-client';
function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const authUser = useSelector((x) => x.client);

  const PUBLIC_ROUTES = [
    '/',
    '/Signup',
    '/404',
    '/ForgotPassword',
    '/resetpassword'
  ];

  const isPublicPage = PUBLIC_ROUTES.some((route) => {
    if (route === '/resetpassword') {
      return location.pathname.startsWith(route);
    }
    return location.pathname === route;
  });

  useEffect(() => {
    if (!authUser.accessToken && !isPublicPage) {
      navigate('/');
    } else if (authUser.accessToken && location.pathname === '/') {
      navigate('/dashboards');
    }
  }, [authUser, isPublicPage, location, navigate]);

  if (!authUser.accessToken && !isPublicPage) {
    return null;
  }

  return children;
}

function App() {
  const content = useRoutes(router);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const location = useLocation();
  const isMessengerPage = location.pathname === '/dashboards/messenger';

  const toggleChatbot = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="App">
          {!isMessengerPage && (
            <button className="chatbot-toggle-button" onClick={toggleChatbot}>
              <img src={chatbotIcon} alt="Chatbot Icon" />
            </button>
          )}
          {!isMessengerPage && isChatbotOpen && (
            <div className="chatbot-container">
              <Chatbot
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
              />
            </div>
          )}
          <CssBaseline />
          <ProtectedRoute>{content}</ProtectedRoute>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
