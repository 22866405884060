/* eslint-disable consistent-return */
import api from './config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const initialState = {
  isLoading: false,
  error: null,
  response: null,
  isSignupFulfilled: false
};
export const signUpMethod = createAsyncThunk(
  'signUp/submitForms',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.post('/auth/signup-client', formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const signUpGoogle = createAsyncThunk(
  'auth/signUpWithGoogle',
  async (_, { rejectWithValue }) => {
    try {
      window.location.href =
        'https://accounts.google.com/o/oauth2/v2/auth?' +
        'response_type=code&' +
        'redirect_uri=http%3A%2F%2Flocalhost%3A1234%2Fauth%2Fgoogle%2Fcallback&' +
        'scope=email%20profile&' +
        'client_id=86511717553-qoqtdlbevtrbhpbql5bumcqdjaba7mtk.apps.googleusercontent.com';
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const signUpFacebook = createAsyncThunk(
  'auth/signUpWithFacebook',
  async (_, { rejectWithValue }) => {
    try {
      window.location.href =
        'https://www.facebook.com/login.php?skip_api_login=1&api_key=3560737634193684&kid_directed_site=0&' +
        'app_id=3560737634193684&signed_next=1&next=https%3A%2F%2Flocalhost%3A1234%2Fauth%2Ffacebook%2Fcallback&' +
        'cancel_url=https%3A%2F%2Flocalhost%3A1234%2Fauth%2Ffacebook%2Fcallback%3Ferror%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied%23_%3D_&' +
        'display=page&locale=fr_FR&pl_dbl=0';
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const confirmPhone = createAsyncThunk(
  'signUp/confirmPhone',
  async (confirmPhoneDTO, { rejectWithValue }) => {
    try {
      const response = await api.post('/auth/confirm-phone', confirmPhoneDTO);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const verifyCodeClient = createAsyncThunk(
  'signUp/verify-code-client',
  async (codeData, { rejectWithValue }) => {
    try {
      console.log(codeData);

      const response = await api.post('auth/verify-code-client', codeData);
      console.log(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const formSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signUpMethod.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(signUpMethod.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
        state.isSignupFulfilled = true; // Set isSignupFulfilled to true
      })
      .addCase(signUpMethod.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.isSignupFulfilled = false;
      })
      .addCase(confirmPhone.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(confirmPhone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
        state.isSignupFulfilled = true; // Set isSignupFulfilled to true
      })
      .addCase(confirmPhone.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.isSignupFulfilled = false;
      })
      .addCase(signUpGoogle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
        state.isSignupFulfilled = true; // Set isSignupFulfilled to true
      })
      .addCase(signUpGoogle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.isSignupFulfilled = false;
      })
      .addCase(signUpFacebook.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
        state.isSignupFulfilled = true; // Set isSignupFulfilled to true
      })
      .addCase(signUpFacebook.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.isSignupFulfilled = false;
      })
      .addCase(verifyCodeClient.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(verifyCodeClient.fulfilled, (state, action) => {
        state.isLoading = false;
        //console.log(action.payload)
        state.response = action.payload;
        state.isSignupFulfilled = true; // Set isSignupFulfilled to true
      })
      .addCase(verifyCodeClient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.isSignupFulfilled = false;
      });
  }
});

export const { setSignupFulfilled } = formSlice.actions;

export default formSlice.reducer;
