import {
  alpha,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState, useCallback } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';
import { formatDistance } from 'date-fns';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router';
import api from 'src/redux/config';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllNotification } from 'src/redux/notificationSlice';
import { io } from 'socket.io-client';
import { debounce } from 'lodash';
import { fetchClaimMobileByID } from 'src/redux/claimsSlice';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.9)};
        color: white;
        min-width: 16px; 
        height: 16px;
        padding: 0;
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications({ fetchNotification }) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(null);
  const [notifs, setNotifs] = useState([]);
  const [company, setCompany] = useState();
  const dispatch = useDispatch();
  const authToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const fetchNotifications = useCallback(
    debounce(async () => {
      const response = await dispatch(fetchAllNotification());
      setNotifs(response.payload);
    }, 300),
    [dispatch]
  );
  useEffect(() => {
    fetchNotifications();

    const socket = io('http://localhost:1235', {
      extraHeaders: {
        Authorization: authToken
      }
    });

    socket.on('connect', () => {
      console.log('Connected to socket server');
    });

    socket.on('connect_error', (err) => {
      console.log('Connection Error:', err);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from socket server');
    });

    socket.on('notif-message', async ({ message }) => {
      fetchNotifications();
    });

    return () => {
      socket.disconnect();
    };
  }, [authToken, dispatch, fetchNotifications]);

  const handleNotificationClick = useCallback(
    async (notif) => {
      try {
        await api.put(`/notification/${notif._id}`);
        fetchNotifications();

        if (notif.kind === 'message') {
          navigate('/dashboards/messenger');
          setTimeout(
            () => window.scrollTo({ top: 1300, behavior: 'smooth' }),
            500
          );
        } else if (notif.kind === 'Claim') {
          navigate('/management/claims');
        }
      } catch (err) {
        console.log(err);
      }
      handleClose();
    },
    [fetchNotifications, navigate]
  );

  const handleDeleteNotification = useCallback(
    async (e, notif) => {
      e.stopPropagation();
      try {
        await api.delete(`/notification/${notif._id}`);
        fetchNotifications();
      } catch (err) {
        console.log(err);
      }
    },
    [fetchNotifications]
  );

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={
              notifications.filter((notification) => !notification.view).length
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Notifications</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          {notifications.length > 0 ? (
            notifications.map((notif, index) => (
              <ListItem
                button
                onClick={() => handleNotificationClick(notif)}
                key={index}
                sx={{
                  p: 2,
                  minWidth: 350,
                  display: { xs: 'block', sm: 'flex' },
                  backgroundColor: !notif.view
                    ? 'rgba(151, 181, 151, 0.138)'
                    : 'none'
                }}
              >
                <Box
                  flex="1"
                  onMouseEnter={() => setIsHovered(index)}
                  onMouseLeave={() => setIsHovered(null)}
                >
                  {isHovered === index && (
                    <Grid
                      container
                      justifyContent="flex-end"
                      marginTop={-1.5}
                      marginLeft={1.6}
                      marginBottom={-0.5}
                    >
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={(e) => handleDeleteNotification(e, notif)}
                      >
                        <CloseIcon sx={{ fontSize: 12 }} />
                      </IconButton>
                    </Grid>
                  )}
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {notif?.kind}
                      {!notif?.view && (
                        <FiberManualRecordIcon
                          sx={{
                            fontSize: 12,
                            marginLeft: '5px',
                            color: '#18e68e'
                          }}
                        />
                      )}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ textTransform: 'none' }}
                    >
                      {formatDistance(
                        new Date(),
                        new Date(
                          notif?.createdAt ? notif.createdAt : new Date()
                        ),
                        { addSuffix: true }
                      )}
                    </Typography>
                  </Box>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    {notif?.message}
                  </Typography>
                </Box>
              </ListItem>
            ))
          ) : (
            <ListItem
              sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
            >
              <Box flex="1">
                <Box display="flex" justifyContent="space-between">
                  <Typography sx={{ fontWeight: 'bold' }}>
                    No notification at the moment.
                  </Typography>
                </Box>
              </Box>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
