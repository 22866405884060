import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import signup from './signup';
import forgetPasswordSlice from './forgetPasswordSlice';
import claimsSlice from './claimsSlice';
import companySlice from './companySlice';
import notificationSlice from './notificationSlice';

export const store = configureStore({
  reducer: {
    notifications: notificationSlice,
    client: authSlice,
    signup: signup,
    forgotPassword: forgetPasswordSlice,
    claims: claimsSlice,
    companies: companySlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
