import api from './config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';

const initialState = {
  claims: [],
  claim: null,
  loading: false,
  isSuccess: false,
  messages: {},
  errors: []
};
export const fetchClaims = createAsyncThunk(
  'claims/fetchClaims',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/claim/userClaims', { params });
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        console.log(error);
        throw error;
      }
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchClaimByID = createAsyncThunk(
  'claims/fetchClaimByID',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get(`/claim/getDetailClaims/${id}`);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchClaimMobileByID = createAsyncThunk(
  'claims/fetchClaimMobileByID',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get(`/claim/getDetailClaims-Mobile/${id}`);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const addMessage = createAsyncThunk(
  'claims/addMessage',
  async ({ id, message }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(`/claim/${id}/add-message`, { message });
      const test = dispatch(fetchClaimByID(id));

      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const AddMediaMessage = createAsyncThunk(
  'claim/AddMediaMessage',
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(`/claim/${id}/add-files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      dispatch(fetchClaimByID(id));

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const AddClaim = createAsyncThunk(
  'claim/addClaim',
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const token = await localStorage.getItem('accessToken');

      const response = await api.post(`/claim`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
export const likeClaim = createAsyncThunk(
  'claim/likeClaim',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const token = await localStorage.getItem('accessToken');
      const response = await api.put(`/claim/like-claim/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const dislikeClaim = createAsyncThunk(
  'claim/dislikeClaim',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const token = await localStorage.getItem('accessToken');
      const response = await api.put(`/claim/dislike-claim/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const claimsSlice = createSlice({
  name: 'claims',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClaims.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchClaims.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.claims = payload?.data || [];
        state.recordsTotal = payload?.recordsTotal || 0;
        state.loading = false;
      })
      .addCase(fetchClaims.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(fetchClaimByID.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchClaimByID.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.claim = payload;
        state.loading = false;
      })
      .addCase(fetchClaimByID.rejected, (state, { payload }) => {
        state.isSuccess = false;

        state.loading = false;
      })
      .addCase(addMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(addMessage.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.message = payload;
        state.loading = false;
      })
      .addCase(addMessage.rejected, (state, { payload }) => {
        state.isSuccess = false;
        state.loading = false;
        state.errors = payload;
      })
      .addCase(AddMediaMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddMediaMessage.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.message = payload;
        state.loading = false;
      })
      .addCase(AddMediaMessage.rejected, (state, { payload }) => {
        state.isSuccess = false;
        state.loading = false;
        state.errors = payload;
      })
      .addCase(AddClaim.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddClaim.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.message = payload;
        state.loading = false;
      })
      .addCase(AddClaim.rejected, (state, { payload }) => {
        state.isSuccess = false;
        state.loading = false;
        state.errors = payload;
      })
      .addCase(likeClaim.pending, (state) => {
        state.loading = true;
      })
      .addCase(likeClaim.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.message = payload;
        state.loading = false;
      })
      .addCase(likeClaim.rejected, (state, { payload }) => {
        state.isSuccess = false;
        state.loading = false;
        state.errors = payload;
      })
      .addCase(dislikeClaim.pending, (state) => {
        state.loading = true;
      })
      .addCase(dislikeClaim.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.message = payload;
        state.loading = false;
      })
      .addCase(dislikeClaim.rejected, (state, { payload }) => {
        state.isSuccess = false;
        state.loading = false;
        state.errors = payload;
      })
      .addCase(fetchClaimMobileByID.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchClaimMobileByID.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.claim = payload;
        state.loading = false;
      })
      .addCase(fetchClaimMobileByID.rejected, (state, { payload }) => {
        state.isSuccess = false;

        state.loading = false;
      });
  }
});

export default claimsSlice.reducer;
