/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
import api from './config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';

const initialState = {
  notifications: [],
  loading: false,
  isSuccess: false,
  errors: []
};

export const fetchAllNotification = createAsyncThunk(
  'notification/fetchAllNotification',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const token = await localStorage.getItem('accessToken');

      const response = await api.get('/notification/receiver', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const markAsViewNotification = createAsyncThunk(
  'notification/markAsViewNotification',
  async (notifId, { rejectWithValue, dispatch }) => {
    try {
      console.log(notifId);

      const token = await localStorage.getItem('accessToken');

      const response = await api.put(`/notification/${notifId}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      // Optionally, fetch notifications again if needed
      dispatch(fetchAllNotification());
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllNotification.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.errors = [];
      })
      .addCase(fetchAllNotification.fulfilled, (state, { payload }) => {
        state.notifications = payload;
        state.isSuccess = true;
        state.loading = false;
      })
      .addCase(fetchAllNotification.rejected, (state, { payload }) => {
        state.errors =
          payload?.statusCode === 400
            ? payload?.message
            : ['Failed to fetch notifications.'];
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(markAsViewNotification.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.errors = [];
      })
      .addCase(markAsViewNotification.fulfilled, (state, { payload }) => {
        state.notifications = state.notifications.map((notif) =>
          notif._id === payload._id ? { ...notif, view: true } : notif
        );
        state.isSuccess = true;
        state.loading = false;
      })
      .addCase(markAsViewNotification.rejected, (state, { payload }) => {
        state.errors =
          payload?.statusCode === 400
            ? payload?.message
            : ['Failed to mark notification as viewed.'];
        state.isSuccess = false;
        state.loading = false;
      });
  }
});

export default notificationSlice.reducer;
