// in ActionProvider.jsx
import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleHello = () => {
    const botMessage = createChatBotMessage('Hello. Nice to meet you.');

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage]
    }));
  };

  const handleWhatIsCliClaim = () => {
    const botMessage = createChatBotMessage(
      'CliClaim is a platform that allows you to make claims to various establishments such as coffee shops, lounges, bars, and hotels.'
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage]
    }));
  };

  const handleHowToMakeAClaim = () => {
    const botMessage = createChatBotMessage(
      'To make a claim, simply follow the steps provided on our platform. You can start by providing details about the establishment and the nature of your claim.'
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage]
    }));
  };
  const howToModifyMyInformations = () => {
    const botMessage = createChatBotMessage(
      'To change your informations you can access your profile page by clicking on the  profil icon on the sidebar and there you can  change you firstname ,lastname,phone number and your password .'
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage]
    }));
  };
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleWhatIsCliClaim,
            handleHowToMakeAClaim,
            howToModifyMyInformations
          }
        });
      })}
    </div>
  );
};

export default ActionProvider;
