// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/chatbot.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".botAvatar {\n  width: 40px; /* Adjust to control the size of the image within the circle */\n  height: 40px; /* Adjust to control the size of the image within the circle */\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  margin-right: 15px;\n}\n.botAvatarWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n}\n", "",{"version":3,"sources":["webpack://./src/content/applications/chatbot/chatbotComponents/botAvatar.css"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAE,8DAA8D;EAC3E,YAAY,EAAE,8DAA8D;EAC5E,yDAA8D;EAC9D,2BAA2B;EAC3B,4BAA4B;EAC5B,wBAAwB;EACxB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;AACzB","sourcesContent":[".botAvatar {\n  width: 40px; /* Adjust to control the size of the image within the circle */\n  height: 40px; /* Adjust to control the size of the image within the circle */\n  background-image: url('../../../../assets/images/chatbot.png');\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  margin-right: 15px;\n}\n.botAvatarWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
